<template>
	<div class="contact-form">
		<v-container>
			<v-form
				class="contact-form__form"
				:action="formAction"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				name="SydKidsPsychContact"
				method="post"
			>
				<v-row>
					<v-col cols="12" sm="6" md="5">
						<input type="hidden" name="form-name" value="SydKidsPsychContact">
						<input name="bot-field" class="d-none">
						<v-text-field
							v-model="form.name"
							autocorrect="off"
							autocomplete="name"
							label="First & Last Name"
							name="name"
							outlined
							prepend-icon="$vuetify.icons.user"
							rounded
							:rules="[rules.required]"
							:success="valid"
							type="text"
							class="contact-form__input"
						/>
						<v-text-field
							v-model="form.email"
							autocapitalize="off"
							autocomplete="email"
							autocorrect="off"
							label="Email"
							name="email"
							outlined
							prepend-icon="$vuetify.icons.contact"
							rounded
							:rules="[rules.required, rules.email]"
							:success="valid"
							type="email"
							class="contact-form__input"
							@input="emailCheck"
						/>
						<v-text-field
							v-model="form.postcode"
							autocomplete="postal-code"
							autocorrect="off"
							label="Postcode"
							maxlength="4"
							name="postcode"
							outlined
							prepend-icon="$vuetify.icons.map"
							rounded
							:rules="[rules.required, rules.numeric, rules.minLengthPostcode]"
							:success="valid"
							type="text"
							class="contact-form__input"
							@input="postcodeCheck"
						/>
						<v-text-field
							v-model="form.telephone"
							autocomplete="tel"
							autocorrect="off"
							label="Telephone"
							maxlength="10"
							name="telephone"
							outlined
							prepend-icon="$vuetify.icons.phone"
							rounded
							:rules="[rules.required, rules.numeric, rules.minLengthTelephone]"
							:success="valid"
							type="tel"
							class="contact-form__input"
							@input="telCheck"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="7">
						<v-select
							v-model="form.services"
							:items="select"
							label="Enquiring About"
							multiple
							name="services"
							outlined
							prepend-icon="$vuetify.icons.faqs"
							rounded
							:success="valid"
							:rules="[rules.required, rules.notEmpty]"
							class="contact-form__input"
							@input="servicesCheck"
						/>
						<input :value="form.services" name="enquiry" type="hidden">
						<v-textarea
							v-model="form.comments"
							height="228px"
							label="Comments"
							name="comments"
							outlined
							prepend-icon="$vuetify.icons.comment"
							rounded
							:success="valid"
							:rules="[rules.required]"
							class="contact-form__input"
						/>
					</v-col>
					<v-col cols="12" class="text-center">
						<v-btn
							color="primary"
							x-large
							type="submit"
							depressed
							:disabled="!valid"
							rounded
							class="contact-form__submit"
						>
							Contact Us
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-container>
	</div>
</template>

<script>
export default {
	name: 'ContactForm',
	data() {
		return {
			form: {
				name: null,
				email: null,
				postcode: null,
				telephone: null,
				services: null,
				comments: null
			},
			rules: {
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'please complete e-mail';
				},
				minLengthPostcode: (value) =>
					(!!this.form.postcode && this.form.postcode.length >= 4) ||
					'please complete postcode',
				minLengthTelephone: (value) =>
					(!!this.form.telephone &&
					this.form.telephone.length >= 10) ||
					'please complete telephone',
				notEmpty: (value) =>
					(!!this.form.services && !!this.form.services.length) ||
					'required',
				numeric: (value) => {
					const pattern = /^[0-9]*$/;
					return pattern.test(value) || 'numbers only';
				},
				required: (value) => !!value || 'required'
			},
			select: [
				'Our Services',
				'Autism Spectrum Disorder',
				'Intellectual Functioning',
				'School Readiness',
				'Academic Assessment',
				'Attention Difficulties',
				'Comprehensive Neuropsychology',
				'Other'
			]
		};
	},
	computed: {
		formAction() {
			return '/success?enquiry=' + encodeURIComponent(this.form.services);
		},
		valid() {
			return !!(
				this.form.name &&
				this.form.email &&
				this.form.postcode &&
				this.form.telephone &&
				this.form.services &&
				this.form.comments
			);
		}
	},
	methods: {
		emailCheck() {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (this.form.email && !pattern.test(this.form.email)) {
				this.form.email = null;
			}
		},
		postcodeCheck() {
			if (this.form.postcode && this.form.postcode.length < 4) {
				this.form.postcode = null;
			}
			const pattern = /^[0-9]*$/;
			if (this.form.postcode && !pattern.test(this.form.postcode)) {
				this.form.postcode = null;
			}
		},
		servicesCheck() {
			if (this.form.services && !this.form.services.length) {
				this.form.services = null;
			}
		},
		telCheck() {
			if (this.form.telephone && this.form.telephone.length < 10) {
				this.form.telephone = null;
			}
			const pattern = /^[0-9]*$/;
			if (this.form.telephone && !pattern.test(this.form.telephone)) {
				this.form.telephone = null;
			}
		}
	}
};
</script>

<style lang="scss">
#app {
	.contact-form {
		&__input {
			.v-input__slot {
				background: $white !important;
				textarea {
					height: 100%;
					resize: none;
				}
			}
		}
	}
}
</style>

<style scoped lang="scss">
.contact-form {
	&__form {
		margin: 0 auto;
		max-width: 1232px;
		padding: gutter-size(4);
	}
	@media only screen and (min-width: #{map-get($grid-breakpoints, "md")}) {
		&__form {
			padding: gutter-size(8);
		}
	}
}
</style>
